import React, {useEffect, useState} from 'react';
import {Alert, Button, Form, Modal, Spinner} from 'react-bootstrap';
import {useData} from '../../context/dataContext';

import {getEaseeSite, getEaseeSites} from '../../../Firebase';

import {ChargerTypes, evChargerTemplate} from '../../constants/evChargerConstants';
import {NodeTypes} from "../../constants/nodeTypes";

const EvChargerForm = (props) => {
  const {
    evChargerFormData,
    setEvChargerFormData,
    saveEvCharger,
    advancedMode,
    setAdvancedMode,
    isEditMode,
  } = useData();

  const [evChargerType, setEvChargerType] = useState("");

  const [easeeSites, setEaseeSites] = useState([]);
  const [selectedEaseeSite, setSelectedEaseeSite] = useState("");
  const [easeeSite, setEaseeSite] = useState({});
  const [easeeAccessToken, setEaseeAccessToken] = useState("");
  const [easeeError, setEaseeError] = useState("");

  const {findNodeByName, selectedNode, treeData} = useData();
  const [selectedNodeData, setSelectedNodeData] = useState({});


// 2054@catch.smartchargecontroller.ch
// Loodo7Fa

  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setEvChargerFormData({
      ...evChargerFormData,
      [e.target.name]: e.target.value,
    });
  }

  const setFormData = () => {
    let node = findNodeByName(treeData, selectedNode);
    if (node.type === NodeTypes.ADDNODE) {
      setEvChargerFormData(evChargerTemplate[evChargerType]);
      return;
    }

    let attributeKeys = Object.keys(node.attributes);
    let evCharger = node.attributes[attributeKeys[0]];
    setEvChargerType(attributeKeys[0])
    setEvChargerFormData(evCharger);
  }

  const onCredentialsChange = (e) => {
    setEvChargerFormData({
      ...evChargerFormData,
      credentials: {
        ...evChargerFormData?.credentials,
        [e.target.name]: e.target.value,
      },
    });
  }

  const onIncreaseChange = (e) => {
    setEvChargerFormData({
      ...evChargerFormData,
      increase: {
        ...evChargerFormData?.increase,
        [e.target.name]: e.target.value,
      },
    });
  }

  const onDecreaseChange = (e) => {
    setEvChargerFormData({
      ...evChargerFormData,
      decrease: {
        ...evChargerFormData?.decrease,
        [e.target.name]: e.target.value,
      },
    });
  }

  const fetchEaseeSites = async () => {
    setLoading(true);
    const data = await getEaseeSites(evChargerFormData?.credentials?.username, evChargerFormData?.credentials?.password);
    if (data.sites) {
      setEaseeSites(data.sites);
      setEaseeAccessToken(data.accessToken);
    } else {
      setEaseeError(data.error);
      setEaseeSites([]);
      setEaseeAccessToken("");
    }
    setLoading(false);
  }

  // called when an easee site is selected in the dropdown
  const fetchEaseeSite = async (e) => {
    setLoading(true);
    const siteId = e.target.value;
    setSelectedEaseeSite(siteId);

    // update evChargerFormData
    setEvChargerFormData({
      ...evChargerFormData,
      siteId: siteId,
    });
    const data = await getEaseeSite(siteId, easeeAccessToken);
    console.log(data)
    if (data.site) {
      setEaseeSite(data.site);
    } else {
      setEaseeError(data.error);
      setEaseeSite({});
    }
    setLoading(false);
  }

  useEffect(() => {
    console.log(`easeeSite: ${JSON.stringify(easeeSite, null, 2)}`);
  }, [easeeSite]);

  useEffect(() => {
    setEvChargerFormData(evChargerTemplate[evChargerType]);
    if (isEditMode) {
      setFormData();
    }
  }, [evChargerType]);

  useEffect(() => {
    setFormData()
  }, [props.show]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>Ladestation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Typ der Ladestation {evChargerType}</Form.Label>
            <Form.Select
              name="deviceName"
              value={evChargerType}
              onChange={(e) => setEvChargerType(e.target.value)}
            >
              <option value="" disabled>Auswählen</option>
              <option value={ChargerTypes.ALFEN}>Alfen SCN</option>
              <option value={ChargerTypes.EASEE}>Easee Circuit</option>
              <option value={ChargerTypes.ZAPTEC}>Zaptec Installation</option>
              <option value={ChargerTypes.ALPITRONIC}>Alpitronic HYC</option>
              <option value={ChargerTypes.ALFEN_SOCKET}>Alfen Socket</option>
              <option value={ChargerTypes.TECHNAGON_AC}>Technagon AC</option>
            </Form.Select>
          </Form.Group>
          {evChargerFormData &&
            <>
              {(evChargerType === ChargerTypes.ALFEN || evChargerType === ChargerTypes.ALPITRONIC) &&
                <Form.Group>
                  <Form.Label>IP-Adresse</Form.Label>
                  <Form.Control
                    type="text"
                    name="ip"
                    value={evChargerFormData?.ip}
                    onChange={onChange}
                  />
                </Form.Group>
              }
              {(evChargerType === ChargerTypes.EASEE || evChargerType === ChargerTypes.ZAPTEC) &&
                <>
                  <Form.Group>
                    <Form.Label>Benutzername</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      value={evChargerFormData?.credentials?.username}
                      onChange={onCredentialsChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Passwort</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      value={evChargerFormData?.credentials?.password}
                      onChange={onCredentialsChange}
                    />
                  </Form.Group>
                </>
              }
              {evChargerType === ChargerTypes.ZAPTEC &&
                <Form.Group>
                  <Form.Label>Installation ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="installationId"
                    value={evChargerFormData?.installationId}
                    onChange={onChange}
                  />
                </Form.Group>
              }

              {evChargerType === ChargerTypes.EASEE &&
                <Form.Group>
                  {loading ? <Spinner animation="border"/> : <>
                    <Button onClick={fetchEaseeSites}
                            disabled={!evChargerFormData?.credentials?.username || !evChargerFormData?.credentials?.password}>Easee
                      Installation abrufen</Button>
                    {easeeError && <Alert variant="danger">{easeeError}</Alert>}
                    {easeeSites.length > 0 &&
                      <Form.Select
                        name="siteId"
                        value={selectedEaseeSite}
                        onChange={fetchEaseeSite}
                      >
                        <option value="" disabled>Auswählen</option>
                        {easeeSites.map((site) => (
                          <option value={site.id} key={site.id}>{site.name}</option>
                        ))}
                      </Form.Select>
                    }
                    {easeeSite?.circuits?.length > 0 &&
                      <Form.Select
                        name="circuitId"
                        value={evChargerFormData?.circuitId}
                        onChange={onChange}
                      >
                        <option value="" disabled>Auswählen</option>
                        {easeeSite?.circuits.map((circuit) => (
                          <option value={circuit.id} key={circuit.id}>{circuit.panelName}</option>
                        ))}
                      </Form.Select>
                    }
                  </>}
                </Form.Group>
              }
              {evChargerType === ChargerTypes.ALFEN_SOCKET &&
                <>
                  <Form.Group>
                    <Form.Label>IP-Adresse</Form.Label>
                    <Form.Control
                      type="text"
                      name="ip"
                      value={evChargerFormData?.ip}
                      onChange={onChange}
                    />
                    <Form.Label>Port</Form.Label>
                    <Form.Control
                      type="number"
                      name="port"
                      value={evChargerFormData?.port}
                      onChange={onChange}
                    />
                    <Form.Label>Socket ID:</Form.Label>
                    <div className={"d-flex"}>
                      <Form.Check type="radio" label="Linker Socket" name="socketId"
                                  checked={evChargerFormData?.socketId === 1}
                                  onChange={(e) =>
                                    setEvChargerFormData({...evChargerFormData, socketId: 1,})}/>
                      <Form.Check className={"ms-2"} type="radio" label="Rechter Socket" name="socketId"
                                  checked={evChargerFormData?.socketId === 2}
                                  onChange={(e) =>
                                    setEvChargerFormData({...evChargerFormData, socketId: 2,})}/>
                    </div>
                    <Form.Label>MMinimaler Ladestrom [A]</Form.Label>
                    <Form.Control
                      type="number"
                      name="minCurrent"
                      value={evChargerFormData?.minCurrent}
                      onChange={onChange}
                    />
                    <Form.Group>
                      <Form.Label>Increase type</Form.Label>
                      <Form.Select
                        name="type"
                        value={evChargerFormData?.increase?.type}
                        onChange={onIncreaseChange}
                      >
                        <option value="absolut">Absolut</option>
                        <option value="ratio">Ratio</option>
                      </Form.Select>
                      <Form.Label>Increase value</Form.Label>
                      <Form.Control
                        type="number"
                        name="value"
                        value={evChargerFormData?.increase?.value}
                        onChange={onIncreaseChange}
                      />
                    </Form.Group>
                  </Form.Group>
                </>}
              {evChargerType === ChargerTypes.TECHNAGON_AC &&
                <>
                  <Form.Group>
                    <Form.Label>IP-Adresse</Form.Label>
                    <Form.Control
                      type="text"
                      name="ip"
                      value={evChargerFormData?.ip}
                      onChange={onChange}
                    />
                    <Form.Label>Port</Form.Label>
                    <Form.Control
                      type="number"
                      name="port"
                      value={evChargerFormData?.port}
                      onChange={onChange}
                    />
                    <Form.Label>Charging Point:</Form.Label>
                    <div className={"d-flex"}>
                      <Form.Check type="radio" label="Charging Point 1" name="socketId"
                                  checked={evChargerFormData?.socketId === 1}
                                  onChange={(e) =>
                                    setEvChargerFormData({...evChargerFormData, socketId: 1,})}/>
                      <Form.Check className={"ms-2"} type="radio" label="Charging Point 2" name="socketId"
                                  checked={evChargerFormData?.socketId === 2}
                                  onChange={(e) =>
                                    setEvChargerFormData({...evChargerFormData, socketId: 2,})}/>
                    </div>
                    <Form.Label>Minimaler Ladestrom [A]</Form.Label>
                    <Form.Control
                      type="number"
                      name="minCurrent" 
                      value={evChargerFormData?.minCurrent}
                      onChange={onChange}
                    />
                  </Form.Group>
                </>
              }

              {advancedMode &&
                <>
                  {evChargerType === ChargerTypes.ALPITRONIC ?
                    <Form.Group>
                      <Form.Label>Minimale Ladeleistung</Form.Label>
                      <Form.Control

                        type="number"
                        name="minPowerW"
                        value={evChargerFormData?.minPowerW}
                        onChange={onChange}
                      />
                    </Form.Group> :
                    <Form.Group>
                      <Form.Label>Minimale Ladestromstärke</Form.Label>
                      <Form.Control
                        type="number"
                        name="minCurrent"
                        value={evChargerFormData?.minCurrent}
                        onChange={onChange}
                      />
                    </Form.Group>
                  }
                  <Form.Group>
                    <Form.Label>Increase type</Form.Label>
                    <Form.Select
                      name="type"
                      value={evChargerFormData?.increase?.type}
                      onChange={onIncreaseChange}
                    >
                      <option value="absolut">Absolut</option>
                      <option value="ratio">Ratio</option>
                    </Form.Select>
                    <Form.Label>Increase value</Form.Label>
                    <Form.Control
                      type="number"
                      name="value"
                      value={evChargerFormData?.increase?.value}
                      onChange={onIncreaseChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Decrease type</Form.Label>
                    <Form.Select
                      name="type"
                      value={evChargerFormData?.decrease?.type}
                      onChange={onDecreaseChange}
                    >
                      <option value="absolut">Absolut</option>
                      <option value="ratio">Ratio</option>
                    </Form.Select>
                    <Form.Label>Decrease value</Form.Label>
                    <Form.Control
                      type="number"
                      name="value"
                      value={evChargerFormData?.decrease?.value}
                      onChange={onDecreaseChange}
                    />
                  </Form.Group>
                </>
              }
            </>}

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => saveEvCharger(evChargerType)}
                disabled={evChargerType === "select"}>Speichern</Button>
      </Modal.Footer>
    </>

  )
}

export default EvChargerForm;