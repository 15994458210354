import React, {useState, useEffect} from 'react';
import {Button, Form, Modal, Alert} from 'react-bootstrap';
import {useData} from '../../context/dataContext';
import {NodeTypes} from "../../constants/nodeTypes";
import {circuitTemplate} from "../../constants/JsonTemplates";

const CircuitForm = (props) => {
  const {circuitFormData, setCircuitFormData, saveCircuit, getDeviceNames} = useData();

  const [lastabwurf, setLastabwurf] = useState(""); // "", no, nc
  const [lastabwurfDevice, setLastabwurfDevice] = useState("");

  const {findNodeByName, selectedNode, treeData} = useData();
  const [selectedNodeData, setSelectedNodeData] = useState({});
  const {isEditMode} = useData();

  const setSavedFormData = () => {
    let node = findNodeByName(treeData, selectedNode);
    if (node.type === NodeTypes.ADDNODE) {
      setCircuitFormData(circuitTemplate);
      return;
    }

    let formData = {...node.attributes};
    if (formData.actions && formData.actions.length > 0) {
      if (formData.actions[0].value === 1) {
        setLastabwurf('nc');
      } else if (formData.actions[0].value === 0) {
        setLastabwurf('no');
      }
      setLastabwurfDevice(formData.actions[0].deviceName);
    }
    setCircuitFormData(node.attributes);

    return node;
  }

  const handleMeasurementChange = (e) => {
    setCircuitFormData({
      ...circuitFormData,
      measurement: {
        ...circuitFormData.measurement,
        [e.target.name]: e.target.value,
      },
    });
  }

  const onFuseChange = (e) => {
    setCircuitFormData({
      ...circuitFormData,
      controlSettings: {
        ...circuitFormData.controlSettings,
        lowerLimit: e.target.value * 0.8,
        upperLimit: Number(e.target.value),
      },
    });
  }

  const onNameChange = (e) => {
    setCircuitFormData({
      ...circuitFormData,
      circuitName: e.target.value,
    });
  }

  useEffect(() => {
    setSavedFormData();
  }, [props.show]);

  // set action according to lastabwurf
  useEffect(() => {
    if (lastabwurf) {
      setCircuitFormData({
        ...circuitFormData,
        actions: [
          {
            deviceName: lastabwurfDevice,
            value: lastabwurf === 'nc' ? 1 : 0,
            decission: -100,
          }
        ]
      });
    } else {

      setCircuitFormData({
        ...circuitFormData,
        actions: [],
      });
    }
  }, [lastabwurf, lastabwurfDevice]);

  const invalidInput = () => {
    if (circuitFormData.measurement.type === 'device' && circuitFormData.measurement.deviceName === '') {
      return true;
    }
    if (!circuitFormData.controlSettings.upperLimit) {
      return true;
    }
    return false;
  }

  return (
   <>
     <Modal.Header>
       <Modal.Title>Stromkreis</Modal.Title>
     </Modal.Header>
     <Modal.Body>
       <Form>
         <Form.Group>
           <Form.Label>Name</Form.Label>
           <Form.Control
            type="text"
            name="circuitName"
            value={circuitFormData.circuitName}
            onChange={onNameChange}
           />
         </Form.Group>
         <Form.Group>
           <Form.Label>Messung</Form.Label>
           <Form.Select
            name="type"
            value={circuitFormData.measurement.type}
            onChange={handleMeasurementChange}
           >
             <option value="sum">Summe</option>
             <option value="device">Gerät</option>
           </Form.Select>
         </Form.Group>
         {circuitFormData.measurement.type === 'device' &&
          <Form.Group>
            <Form.Label>Messgerät</Form.Label>
            <Form.Select
             name="deviceName"
             value={circuitFormData.measurement.deviceName}
             onChange={handleMeasurementChange}
            >
              <option value="">Auswählen...</option>
              {getDeviceNames().map(({deviceName, deviceType}) => (
               deviceType !== "RpiGPIO" && <option value={deviceName} key={deviceName}>{deviceName}</option>
              ))}
            </Form.Select>
          </Form.Group>
         }
         <Form.Group>
           <Form.Label>Absicherung des Messpunktes [A]</Form.Label>
           <Form.Control
            type="number"
            name="upperLimit"
            value={circuitFormData.controlSettings.upperLimit}
            onChange={onFuseChange}
           />
         </Form.Group>
         <Form.Group>
           <Form.Label>Lastabwurf</Form.Label>
           <Form.Check
            type="radio"
            label="Kein Lastbwurf"
            value="kein_lastbwurf"
            checked={lastabwurf === ""}
            onChange={() => setLastabwurf("")}
            name="radioOptions"
            id="keinLastbwurf"
           />
           <Form.Check
            type="radio"
            label="Lastabwurf: NC, Kontakt im Normalbetrieb geschlossen"
            value="nc"
            checked={lastabwurf === 'nc'}
            onChange={() => setLastabwurf('nc')}
            name="radioOptions"
            id="ncKontakt"
           />
           <Form.Check
            type="radio"
            label="Lastabwurf: NO, Kontakt im Normalbetrieb offen"
            value="no"
            checked={lastabwurf === 'no'}
            onChange={() => setLastabwurf('no')}
            name="radioOptions"
            id="noKontakt"
           />
           {lastabwurf &&
            <Form.Select
             name="deviceName"
             value={lastabwurfDevice}
             onChange={(e) => setLastabwurfDevice(e.target.value)}
            >
              <option value="">Auswählen...</option>
              {getDeviceNames().map(({deviceName, deviceType, deviceSubType}) => (
               (deviceType === "RpiGPIO" || deviceSubType === "SiemensPAC2200") &&
               <option value={deviceName} key={deviceName}>{deviceName}</option>
              ))}
            </Form.Select>
           }
           <small>Sofern dies ein untergeordneter Circuit ist, wird der Lastabwurf vom übergeordneten Circuit
             vererbt.</small>
         </Form.Group>

       </Form>
     </Modal.Body>
     <Modal.Footer>
       <Button variant="primary" onClick={saveCircuit} disabled={invalidInput()}>Speichern</Button>
     </Modal.Footer>
   </>

  )
}

export default CircuitForm;