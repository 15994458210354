import React from "react";

import { Table, Form } from 'react-bootstrap';

const DSMR5Table = (props) => {

  return (   
    <Table>
      <tbody>
        <tr>
          <th className="text-start">Name</th>
          <td className="text-end">{props.device.name}</td>
        </tr>
        <tr>
          <th className="text-start">USB Device</th>
          <td className="text-end">{props.device.usbDevice}</td>
        </tr>
      </tbody>
    </Table>
  )
}

const DSMR5Form= (props) => {
  return (
    <Form>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={props.device.name}
          onChange={props.handleChange} />
      </Form.Group>
      <Form.Group>
        <Form.Label>USB Device</Form.Label>
        <Form.Control
          type="text"
          name="usbDevice"
          value={props.device.usbDevice}
          onChange={props.handleChange} />
      </Form.Group>
    </Form>
  )
}

export { DSMR5Table, DSMR5Form };