import {Card, ListGroup} from "react-bootstrap";
import React from "react";
import Icon from "../../icon";
import NodeDropdown from "../dropdown";
import {useData} from "../../../context/dataContext";

const Circuit = ({nodeDatum}) => {

  const [isHidden, setIsHidden] = React.useState(true);
  const {deleteNode} = useData();
  const handleIconClick = () => {
    setIsHidden(!isHidden)
  };

  return (
   <>
     <Card style={{textAlign: 'left', overflow: 'visible'}} className="shadow mb-3 rounded-2 border-2">
       <Card.Body onChange={handleIconClick}>
         <Card.Title className="mb-0 d-flex justify-content-between align-items-start">
           <div>
             <small style={{fontSize: 10}}>ID: {nodeDatum.name}</small>
             <br/>
             Circuit {nodeDatum.attributes?.circuitName && " - " + nodeDatum.attributes?.circuitName}
           </div>
           <div style={{position: 'relative'}} onClick={handleIconClick}>
             <div>
               <NodeDropdown isHidden={isHidden} nodeData={nodeDatum}/>
             </div>
             <div className="icon-hover">
               <Icon name="cable" color={"#0d6efd"} size={30}/>
             </div>
           </div>
         </Card.Title>
       </Card.Body>
       <ListGroup className="list-group-flush">
         <ListGroup.Item>
           <b>Messung:</b>
           <p className="mb-0">Type: {nodeDatum.attributes?.measurement?.type}</p>
           <p className="mb-0">Device: {nodeDatum.attributes?.measurement?.deviceName}</p>
         </ListGroup.Item>
         <ListGroup.Item>
           <b>Regeleinstellungen</b>
           <p className="mb-0">Entscheidungsintervall: {nodeDatum.attributes?.controlSettings?.decissionInterval}s</p>
           <p className="mb-0">Absicherung: {nodeDatum.attributes?.controlSettings?.upperLimit}A</p>
         </ListGroup.Item>
         <ListGroup.Item>
           <b>Lastabwurf:</b>
           {nodeDatum.attributes?.actions && nodeDatum.attributes?.actions.length > 0 ?
            <>
              <p className="mb-0">Modus:
                {nodeDatum.attributes?.actions[0]?.value === 1 && " NC (Öffner)"}
                {nodeDatum.attributes?.actions[0]?.value === 0 && " NO (Schliesser)"}
                {nodeDatum.attributes?.actions[0]?.value === undefined && " Nicht konfiguriert"}
              </p>
              {nodeDatum.attributes?.actions[0]?.deviceName && (
               <p className="mb-0">Device: {nodeDatum.attributes?.actions[0]?.deviceName}</p>
              )}
            </> :
            <p className="mb-0">Nicht konfiguriert</p>
           }

         </ListGroup.Item>
       </ListGroup>
     </Card>
   </>
  )
}
export default Circuit;
