export const sccSchemaPublicV1 = {
    "$id": "https://smartchargecontroller.ch/scc.schema.json",
    "title": "SCC",
    "description": "SCC configuration file Schema",
    "type": "object",
    "required": [ "deviceId", "mqtt", "circuits" ],
    "additionalProperties": false,
    "properties": {
      "circuits": {
        "description": "Circuit Settings (can be nested)",
        "type": "array",
        "items": {
            "type": "object",
            "additionalProperties": false,
            "required": ["measurement", "controlSettings"],
            "properties": {
                "measurement": {
                    "type": "object",
                    "required": ["type"],
                    "properties": {
                        "type": {
                            "description": "SmartPi: Integrated Measurement, device: External Devices defined in the json definition, sum: sum of ev chargers current usage and nested circuits on this circuit",
                            "type": "string",
                            "enum": ["device", "sum"]
                        }, 
                        "deviceName": {
                            "type": "string"
                        }
                    }
                },
                "controlSettings": {
                    "description": "decrease / increase only required for leaf circuits (which have no nested circuits)",
                    "type": "object",
                    "additionalProperties": false,
                    "required": ["decissionInterval", "lowerLimit", "upperLimit"],
                    "properties": {
                        "decissionInterval": {
                            "description": "Interval in [s] when decissions are made",
                            "type": "integer",
                            "default": 60
                        },
                        "lowerLimit": {
                            "description": "below this value [A], the charging current will be increased",
                            "type": "integer"
                        },
                        "upperLimit": {
                            "description": "above this value [A], the charging current will be decreased",
                            "type": "integer"
                        }
                    }
                },
                "evChargers": {
                    "description": "EV charging stations",
                    "type": "array",
                    "items": {
                        "type": "object",
                        "additionalProperties": false,
                        "properties": {
                            "alfenSCN": {
                                "type": "object",
                                "additionalProperties": false,
                                "properties": {
                                    "ip": {
                                        "description": "IP address of the ICU where active load balancing is activated",
                                        "type": "string"
                                    },
                                    "minCurrent": {
                                        "description": "minimum current to be allocated in order cars start charging, only when below max current of circuit",
                                        "type": "integer"
                                    },
                                    "decrease": {
                                        "description": "method of decreasing, reducing charging current",
                                        "type": "object",
                                        "additionalProperties": false,
                                        "properties": {
                                            "type": {
                                                "type": "string",
                                                "enum": ["absolut", "ratio"]
                                            },
                                            "value": {
                                                "type": "number"
                                            }
                                        }
                                    },
                                    "increase": {
                                        "description": "method of increasing charging current",
                                        "type": "object",
                                        "additionalProperties": false,
                                        "properties": {
                                            "type": {
                                                "type": "string",
                                                "enum": ["absolut", "ratio"]
                                            },
                                            "value": {
                                                "type": "number"
                                            }
                                        }
                                    }
                                }
                            },
                            "alfenSocket": {
                                "type": "object",
                                "additionalProperties": false,
                                "properties": {
                                    "ip": {
                                        "description": "IP address of the ICU where active load balancing is activated",
                                        "type": "string"
                                    },
                                    "port": {
                                        "description": "Port of the Alfen Charger, defaulted to 502",
                                        "type": "integer",
                                    },
                                    "socketId": {
                                        "description": "ID of the socket to be controlled, 1 --> left / single socket, 2 --> right socket",
                                        "type": "integer"
                                    },
                                    "minCurrent": {
                                        "description": "minimum current to be allocated in order cars start charging, only when below max current of circuit",
                                        "type": "integer"
                                    },
                                    "decrease": {
                                        "description": "method of decreasing, reducing charging current",
                                        "type": "object",
                                        "additionalProperties": false,
                                        "properties": {
                                            "type": {
                                                "type": "string",
                                                "enum": ["absolut", "ratio"]
                                            },
                                            "value": {
                                                "type": "number"
                                            }
                                        }
                                    },
                                    "increase": {
                                        "description": "method of increasing charging current",
                                        "type": "object",
                                        "additionalProperties": false,
                                        "properties": {
                                            "type": {
                                                "type": "string",
                                                "enum": ["absolut", "ratio"]
                                            },
                                            "value": {
                                                "type": "number"
                                            }
                                        }
                                    }
                                }
                            },
                            "technagonAC": {
                                "type": "object",
                                "additionalProperties": false,
                                "properties": {
                                    "ip": {
                                        "description": "IP address of the Technagon AC charger",
                                        "type": "string"
                                    },
                                    "port": {
                                        "description": "Port of the Technagon AC Charger, defaulted to 502",
                                        "type": "integer"
                                    },
                                    "socketId": {
                                        "description": "ID of the socket to be controlled, 1 --> Charging Point 1, 2 --> Charging Point 2",
                                        "type": "integer"
                                    },
                                    "minCurrent": {
                                        "description": "minimum current to be allocated in order cars start charging, only when below max current of circuit",
                                        "type": "integer"
                                    },
                                    "decrease": {
                                        "description": "method of decreasing, reducing charging current",
                                        "type": "object",
                                        "additionalProperties": false,
                                        "properties": {
                                            "type": {
                                                "type": "string",
                                                "enum": ["absolut", "ratio"]
                                            },
                                            "value": {
                                                "type": "number"
                                            }
                                        }
                                    },
                                    "increase": {
                                        "description": "method of increasing charging current",
                                        "type": "object",
                                        "additionalProperties": false,
                                        "properties": {
                                            "type": {
                                                "type": "string",
                                                "enum": ["absolut", "ratio"]
                                            },
                                            "value": {
                                                "type": "number"
                                            }
                                        }
                                    }
                                }
                            },
                            "easeeCircuit": {
                                "type": "object",
                                "additionalProperties": false,
                                "required": ["siteId", "circuitId", "minCurrent", "credentials"],
                                "properties": {
                                    "siteId": {
                                        "type": "string"
                                    },
                                    "circuitId": {
                                        "type": "string"
                                    },
                                    "minCurrent": {
                                        "description": "minimum current to be allocated in order cars start charging, only when below max current of circuit",
                                        "type": "integer"
                                    },
                                    "credentials": {
                                        "description": "Easee Cloud credentials",
                                        "type": "object",
                                        "additionalProperties": false,
                                        "required": ["username", "password"],
                                        "properties": {
                                            "username": {
                                                "type": "string"
                                            },
                                            "password": {
                                                "type": "string"
                                            }
                                        }
                                    },
                                    "decrease": {
                                        "description": "method of decreasing, reducing charging current",
                                        "type": "object",
                                        "additionalProperties": false,
                                        "properties": {
                                            "type": {
                                                "type": "string",
                                                "enum": ["absolut", "ratio"]
                                            },
                                            "value": {
                                                "type": "number"
                                            }
                                        }
                                    },
                                    "increase": {
                                        "description": "method of increasing charging current",
                                        "type": "object",
                                        "additionalProperties": false,
                                        "properties": {
                                            "type": {
                                                "type": "string",
                                                "enum": ["absolut", "ratio"]
                                            },
                                            "value": {
                                                "type": "number"
                                            }
                                        }
                                    }
                                }
                            },
                            "zaptecInstallation": {
                                "type": "object",
                                "additionalProperties": false,
                                "required": ["installationId", "minCurrent", "credentials"],
                                "properties": {
                                    "installationId": {
                                        "type": "string"
                                    },
                                    "minCurrent": {
                                        "description": "minimum current to be allocated in order cars start charging, only when below max current of circuit",
                                        "type": "integer"
                                    },
                                    "credentials": {
                                        "description": "Zaptec Cloud credentials",
                                        "type": "object",
                                        "additionalProperties": false,
                                        "required": ["username", "password"],
                                        "properties": {
                                            "username": {
                                                "type": "string"
                                            },
                                            "password": {
                                                "type": "string"
                                            }
                                        }
                                    },
                                    "decrease": {
                                        "description": "method of decreasing, reducing charging current",
                                        "type": "object",
                                        "additionalProperties": false,
                                        "properties": {
                                            "type": {
                                                "type": "string",
                                                "enum": ["absolut", "ratio"]
                                            },
                                            "value": {
                                                "type": "number"
                                            }
                                        }
                                    },
                                    "increase": {
                                        "description": "method of increasing charging current",
                                        "type": "object",
                                        "additionalProperties": false,
                                        "properties": {
                                            "type": {
                                                "type": "string",
                                                "enum": ["absolut", "ratio"]
                                            },
                                            "value": {
                                                "type": "number"
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                },
                "actions": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "additionalProperties": false,
                        "properties": {
                            "deviceName": {
                                "type": "string"
                            },
                            "value": {
                                "type": "integer"
                            },
                            "decission": {
                                "description": "Override decission",
                                "type": "number"
                            }
                        }
                    }
                }, 
                "circuits": {
                    "description": "Sub circuits, max. 1 nested supported",
                    "$ref": "#/properties/circuits"
                }
            }
        }
      },
      "devices": {
        "description": "Devices such as GPIO, external Meter, ...",
        "type": "object",
        "additionalProperties": false,
        "properties": {
            "SmartPi": {
                "type": "object",
                "additionalProperties": false,
                "required": ["name"],
                "properties": {
                    "name": {
                        "description": "name of the device, to be referenced from circuits",
                        "type": "string"
                    }
                }
            },
            "ModbusRTUMeter": {
                "type": "array",
                "items": {
                    "type": "object",
                    "additionalProperties": false,
                    "required": ["name", "deviceType", "usbDevice", "modbusId"],
                    "properties": {
                        "name": {
                            "description": "name of the device, to be referenced from circuits",
                            "type": "string"
                        },
                        "deviceType": {
                            "type": "string",
                            "enum": ["PRO380-Mod", "WAGO_2857-570/024-000", "PRO380-Mod-ZEV"]
                        },
                        "usbDevice": {
                            "type": "string",
                            "default": "/dev/ttyUSB0"
                        },
                        "modbusId": {
                            "type": "integer",
                            "default": 1
                        }
                    }
                }
            },
            "DSMR5": {
                "type": "array",
                "items": {
                    "type": "object",
                    "additionalProperties": false,
                    "required": ["name", "deviceType", "usbDevice"],
                    "properties": {
                        "name": {
                            "description": "name of the device, to be referenced from circuits",
                            "type": "string"
                        },
                        "usbDevice": {
                            "type": "string",
                            "default": "/dev/ttyUSB0"
                        }
                    }
                }
            },
            "RpiGPIO": {
                "type": "array",
                "items": {
                    "type": "object",
                    "additionalProperties": false,
                    "required": [],
                    "properties": {
                        "name": {
                            "description": "name of the device, to be referenced from circuits",
                            "type": "string"
                        },
                        "direction": {
                            "type": "string",
                            "enum": ["in"]
                        },
                        "gpio": {
                            "type": "integer"
                        }
                    }
                }
            } 
        }
      }
    }
  }