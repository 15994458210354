import React, {useState, useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {getAuth} from "firebase/auth";
import {Gear, Cpu, Eyeglasses, Recycle, NodePlus} from 'react-bootstrap-icons';
import {Nav} from 'react-bootstrap';

import NodeRedAdmin from './nodeRedAdmin';
import ConfigText from './configText';
import JsonConfig from './jsonConfig';
import Lifecycle from './lifecycle';
import DeviceSettings from './deviceSettings';

import {DataProvider} from '../ConfigTree/context/dataContext';

const Configuration = (props) => {
  const [nodeRedAdmin, setNodeRedAdmin] = useState(false);
  const [lifecycleManagement, setLifecycleManagement] = useState(false);
  const [activeKey, setActiveKey] = useState('sccconfig');

  const {eid} = useParams();

  useEffect(() => {
    getAuth().currentUser.getIdTokenResult().then(idTokenResult => {
      setNodeRedAdmin(idTokenResult?.claims?.nodeRedAdmin);
      setLifecycleManagement(idTokenResult?.claims?.lifecycleManagement);
    });
  }, []);

  const renderContent = (key) => {
    switch (key) {
      case 'sccconfig':
        return <DataProvider><JsonConfig eid={eid}/></DataProvider>;
      case 'deviceSettings':
        return <DeviceSettings eid={eid}/>;
      case 'summary':
        return <ConfigText eid={eid}/>;
      case 'lifecycle':
        return <Lifecycle eid={eid}/>;
      case 'noderedadmin':
        return <NodeRedAdmin nodeRedAdmin={nodeRedAdmin} eid={eid}/>;
      default:
        return null;
    }
  };

  return (
    <div className="configurator">
      <Nav variant="pills" className="flex-row sidebar" activeKey={activeKey}
           onSelect={(selectedKey) => setActiveKey(selectedKey)}
           style={{borderBottom: '1px solid #dee2e6'}}>
        <Nav.Item>
          <Nav.Link eventKey="sccconfig"><Gear/> Konfiguration</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="deviceSettings"><Cpu/> Messwandler</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="summary"><Eyeglasses/> Dokumentation</Nav.Link>
        </Nav.Item>
        {lifecycleManagement &&
          <Nav.Item>
            <Nav.Link eventKey="lifecycle"><Recycle/> Lifecycle</Nav.Link>
          </Nav.Item>
        }
        {nodeRedAdmin &&
          <Nav.Item>
            <Nav.Link eventKey="noderedadmin"><NodePlus/> Node-RED Admin</Nav.Link>
          </Nav.Item>
        }
      </Nav>
      <div className="p-3 sidebar-content">
        {renderContent(activeKey)}
      </div>
    </div>
  );
}

export default Configuration;
