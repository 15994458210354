import React, {useState} from 'react';

import {Alert, Button, Card, Col, Form, Modal, Row} from 'react-bootstrap';
import {Pencil} from 'react-bootstrap-icons';

import {useData} from './context/dataContext';

import {ModbusRTUMeterForm, ModbusRTUMeterTable} from './components/devices/modbusRTUMeter';
import {ModbusTCPMeterForm, ModbusTCPMeterTable} from './components/devices/modbusTCPMeter';
import {RpiGPIOForm, RpiGPIOTable} from './components/devices/rpiGPIO';
import {DSMR5Form, DSMR5Table} from './components/devices/DSMR5';

import {deviceTemplates} from './constants/JsonTemplates';
import {ShellyPro3EmForm, ShellyPro3EmTable} from "./components/devices/shellyPro3EM";

const DeviceDefinition = (props) => {
  const {currentJsonText, updateCurrentJsonText} = useData();

  const [showEditModal, setShowEditModal] = useState(false);

  const [selectedDevice, setSelectedDevice] = useState(null);   // will be changed when form changes
  const [selectedDeviceType, setSelectedDeviceType] = useState(null);
  const [selectedIndexOfDeviceType, setSelectedIndexOfDeviceType] = useState(null);

  const [showConfirm, setShowConfirm] = useState(false);
  const {validateCurrentJson} = useData();

  const handleChange = (event) => {
    const {name, value, type} = event.target;
    console.log("handleChange values: " + name + " " + value);
    console.log("type of selectedDevice: " + typeof selectedDevice);
    console.log("type of value: " + typeof value);

    // Convert value back to a number if it's a numeric field
    const isNumber = type === 'number';
    const parsedValue = isNumber ? Number(value) : value;

    setSelectedDevice({...selectedDevice, [name]: parsedValue});
  }

  const updateData = () => {
    // TODO catch duplicate names
    const newJson = JSON.parse(currentJsonText);
    if (selectedIndexOfDeviceType === null) {
      // new device
      if (!newJson.devices[selectedDeviceType]) newJson.devices[selectedDeviceType] = [];  // create array if not exists
      newJson.devices[selectedDeviceType].push(selectedDevice);
    } else {
      // update device
      newJson.devices[selectedDeviceType][selectedIndexOfDeviceType] = selectedDevice;
    }
    updateCurrentJsonText(JSON.stringify(newJson, null, 2));
    closeModal();
  }

  const deleteDevice = () => {
    if (currentJsonText.match(new RegExp(selectedDevice.name, "g")).length > 1) {
      console.log("show confirm");
      setShowConfirm(true);
      closeModal()
      return
    }

    const newJson = JSON.parse(currentJsonText);
    newJson.devices[selectedDeviceType].splice(selectedIndexOfDeviceType, 1);
    updateCurrentJsonText(JSON.stringify(newJson, null, 2));
    closeModal();
  }

  const editDevice = (type, index, device) => {
    setSelectedDeviceType(type);
    setSelectedIndexOfDeviceType(index);
    setSelectedDevice(device);
    setShowEditModal(true);
  }

  const closeModal = () => {
    setShowEditModal(false);
    setSelectedDeviceType(null);
    setSelectedIndexOfDeviceType(null);
  }

  const addDeviceSelect = (e) => {
    setSelectedDeviceType(e.target.value);
    setSelectedDevice(deviceTemplates[e.target.value]);
    setSelectedIndexOfDeviceType(null);
    setShowEditModal(true);
  }


  const getCard = ({index, type, device}) => {
    return (
      <Col key={index} className="p-2">
        <Card key={index} className='h-100 w-100'>
          <Card.Body className="text-center">
            {type === "SmartPi" ?
              <>
                <h5>SmartPi</h5>
                <p>Integrierte Messung des Smart Charge Controllers</p>
              </>
              :
              type === "RpiGPIO" ?
                <>
                  <h5>Eingang <Button variant="link" onClick={() => {
                    editDevice(type, index, device)
                  }}><Pencil/></Button></h5>
                  <RpiGPIOTable device={device}/>

                </> :
                type === "ModbusRTUMeter" ?
                  <>
                    <h5>Modbus RTU Meter <Button variant="link" onClick={() => {
                      editDevice(type, index, device)
                    }}><Pencil/></Button></h5>
                    <ModbusRTUMeterTable device={device}/>
                  </> :
                  type === "ModbusTCPMeter" ?
                    <>
                      <h5>Modbus TCP Meter <Button variant="link" onClick={() => {
                        editDevice(type, index, device)
                      }}><Pencil/></Button></h5>
                      <ModbusTCPMeterTable device={device}/>
                    </> :
                    type === "DSMR5" ?
                      <>
                        <h5>DSMR5 <Button variant="link" onClick={() => {
                          editDevice(type, index, device)
                        }}><Pencil/></Button></h5>
                        <DSMR5Table device={device}/>
                      </> :
                      type === "ShellyPro3EM" ?
                        <>
                          <h5>Shelly Pro 3EM <Button variant="link" onClick={() => {
                            editDevice(type, index, device)
                          }}><Pencil/></Button></h5>
                          <ShellyPro3EmTable device={device}/>
                        </> :
                        <></>
            }
          </Card.Body>
        </Card>
      </Col>
    )
  }

  const deviceCards = () => {
    let devices = JSON.parse(currentJsonText)?.devices;
    return (
      <>
        {devices?.SmartPi && getCard({index: 0, type: "SmartPi", device: null})}
        {devices?.RpiGPIO?.map((device, index) => getCard({index, type: "RpiGPIO", device: device}))}
        {devices?.ModbusRTUMeter?.map((device, index) => getCard({index, type: "ModbusRTUMeter", device: device}))}
        {devices?.ModbusTCPMeter?.map((device, index) => getCard({index, type: "ModbusTCPMeter", device: device}))}
        {devices?.DSMR5?.map((device, index) => getCard({index, type: "DSMR5", device: device}))}
        {devices?.ShellyPro3EM?.map((device, index) => getCard({index, type: "ShellyPro3EM", device: device}))}
      </>
    )
  }

  if (!validateCurrentJson()) {
    return <>
      <Alert variant="danger">
        Invalid JSON
      </Alert>
    </>
  }

  return (
    JSON.parse(currentJsonText)?.devices ?
      <>
        <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>{"Are you sure?"}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowConfirm(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={deleteDevice}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Row xs={1} md={2} lg={3} className="g-4">
          <div className='float-start'>
            <Form>
              <Form.Group>
                <Form.Select
                  name="addNewDevice"
                  value="select"
                  onChange={addDeviceSelect}
                >
                  <option value="select" disabled>Neues Gerät hinzufügen...</option>
                  <option value="RpiGPIO">Eingang</option>
                  <option value="ModbusRTUMeter">Modbus RTU Meter</option>
                  <option value="ModbusTCPMeter">Modbus TCP Meter</option>
                  <option value="DSMR5">DSMR5</option>
                  <option value="ShellyPro3EM">Shelly Pro 3EM</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </div>
        </Row>
        <br/>
        <Row xs={1} md={2} lg={3} className="g-4">
          {deviceCards()}
        </Row>
        <Modal show={showEditModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Gerät bearbeiten</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedDeviceType === "ModbusRTUMeter" &&
              <ModbusRTUMeterForm device={selectedDevice} handleChange={handleChange}/>}
            {selectedDeviceType === "ModbusTCPMeter" &&
              <ModbusTCPMeterForm device={selectedDevice} handleChange={handleChange}/>}
            {selectedDeviceType === "RpiGPIO" && <RpiGPIOForm device={selectedDevice} handleChange={handleChange}/>}
            {selectedDeviceType === "DSMR5" && <DSMR5Form device={selectedDevice} handleChange={handleChange}/>}
            {selectedDeviceType === "ShellyPro3EM" &&
              <ShellyPro3EmForm device={selectedDevice} handleChange={handleChange}/>}
          </Modal.Body>
          <Modal.Footer>
            {selectedIndexOfDeviceType !== null ? <Button variant="warning" onClick={deleteDevice}>
              Löschen
            </Button> : <></>}
            <Button variant="secondary" onClick={closeModal}>
              Abbrechen
            </Button>
            <Button variant="primary" onClick={updateData}>
              Speichern
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Abhängigkeiten auflösen</Modal.Title>
          </Modal.Header>
          <Modal.Body>{"Andere Komponenten sind von diesem Gerät abhängig. Beheben Sie diese Abhängigkeiten zuerst."}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setShowConfirm(false)}>
              Schliessen
            </Button>
          </Modal.Footer>
        </Modal>
      </> :
      <></>
  );
}

export default DeviceDefinition;