import React from "react";

import {Table, Form} from 'react-bootstrap';

const ModbusTCPMeterTable = (props) => {

  return (
    <Table>
      <tbody>
      <tr>
        <th className="text-start">Typ</th>
        <td className="text-end">{props.device.deviceType}</td>
      </tr>
      <tr>
        <th className="text-start">Name</th>
        <td className="text-end">{props.device.name}</td>
      </tr>
      <tr>
        <th className="text-start">Modbus ID</th>
        <td className="text-end">{props.device.modbusId}</td>
      </tr>
      <tr>
        <th className="text-start">IP</th>
        <td className="text-end">{props.device.ip}</td>
      </tr>
      </tbody>
    </Table>
  )
}

const ModbusTCPMeterForm = (props) => {
  console.log(JSON.stringify(props))
  return (
    <Form>
      <Form.Group>
        <Form.Label>Typ</Form.Label>
        <Form.Select
          name="deviceType"
          value={props.device.deviceType}
          onChange={props.handleChange}
        >
          <option value="select" disabled>auswählen...</option>
          <option value="SiemensPAC2200">Siemens PAC2200</option>
        </Form.Select>
      </Form.Group>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={props.device.name}
          onChange={props.handleChange}/>
      </Form.Group>
      <Form.Group>
        <Form.Label>Modbus ID</Form.Label>
        <Form.Control
          type="number"
          name="modbusId"
          value={props.device.modbusId}
          onChange={props.handleChange}/>
      </Form.Group>
      <Form.Group>
        <Form.Label>IP</Form.Label>
        <Form.Control
          type="text"
          name="ip"
          value={props.device.ip}
          onChange={props.handleChange}/>
      </Form.Group>
    </Form>
  )
}

export {ModbusTCPMeterForm, ModbusTCPMeterTable};