import TreeView from "./tree";
import React from "react";

const ConfigTree = () => {
  return (
    <div className="App d-flex">
      <TreeView/>
    </div>
  );
}

export default ConfigTree;
