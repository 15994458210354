import React from 'react';

import { GoogleMap, LoadScript, Marker, MarkerClusterer } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '70vh',
  marginTop: '10px'
};

const center = {
  lat: 46.8182,
  lng: 8.2275
};

const options = {
  imagePath:
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
}

function createKey(location) {
  return location._lat + location._long
}

const EdgeMap = (props) => {

  return (
    <LoadScript googleMapsApiKey="AIzaSyAMOKqlaiYfsLSwE6GZ__ZUnNacXjClT1g" mapIds={["e36a52774f68c5c7"]}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        options={{ mapId: "e36a52774f68c5c7" }}
        zoom={8}        
      >
        {props.edgeData && props.edgeData.map(eD => 
          eD.geolocation && <Marker key={createKey(eD.geolocation)} position={{lat: eD.geolocation._lat, lng: eD.geolocation._long}}
          />
        )}

      </GoogleMap>
    </LoadScript>
  )
}

export default EdgeMap;
