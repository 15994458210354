import React, { useState, useEffect } from 'react';

import { Form, Button, Row, Col, FormGroup, Spinner, Alert } from 'react-bootstrap';

import { doc, setDoc, onSnapshot, or} from 'firebase/firestore';

import {db} from '../Firebase';


const DeviceSettings = (props) => {
  const [ ctType, setCtType ] = useState('YHDC_SCT013');
  const [ ctPrimaryCurrent, setCtPrimaryCurrent ] = useState('100');
  const [ ctInvert, setCtInvert ] = useState(["0", "0", "0"]);

  const [ originalCtType, setOriginalCtType ] = useState('');
  const [ originalCtPrimaryCurrent, setOriginalCtPrimaryCurrent ] = useState('');
  const [ originalCtInvert, setOriginalCtInvert ] = useState([undefined, undefined, undefined]);

  const [ loading, setLoading ] = useState(false);

  const saveCurrentTransformer = async() => {
    setLoading(true);
    if(ctType === 'YHDC_SCT013') {
      setCtPrimaryCurrent('100');
    }
    await setDoc(doc(db, "edges", props.eid, "balena", "deviceVariables"), {
      SMARTPI_CTTYPE: ctType,
      SMARTPI_PRIMARYCURRENT: ctPrimaryCurrent,
      SMARTPI_CHANGECURRENTDIRECTION_1: ctInvert[0],
      SMARTPI_CHANGECURRENTDIRECTION_2: ctInvert[1],
      SMARTPI_CHANGECURRENTDIRECTION_3: ctInvert[2]
    });
    setLoading(false);
  };

  const handleSwitchChange = (e) => {
    let ctInv = [...ctInvert];
    ctInv[e.target.id] = e.target.checked ? "1" : "0";
    setCtInvert(ctInv);
  };

  const compareArrays = (a, b) => {
    if(a.length !== b.length) {
      return false;
    }
    for(let i = 0; i < a.length; i++) {
      if(a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if(props.eid) {

      const unsubscribe = onSnapshot(doc(db, "edges", props.eid, "balena", "deviceVariables"), (doc) => {
        if(doc.exists()) {
          let data = doc.data();
          let ctInv = ["0", "0", "0"];
          if(data.SMARTPI_CTTYPE) {
            setCtType(data.SMARTPI_CTTYPE);
            setOriginalCtType(data.SMARTPI_CTTYPE);
          }
          if(data.SMARTPI_PRIMARYCURRENT) {
            setCtPrimaryCurrent(data.SMARTPI_PRIMARYCURRENT);
            setOriginalCtPrimaryCurrent(data.SMARTPI_PRIMARYCURRENT);
          }
          
          data.SMARTPI_CHANGECURRENTDIRECTION_1 && (ctInv[0] = data.SMARTPI_CHANGECURRENTDIRECTION_1);
          data.SMARTPI_CHANGECURRENTDIRECTION_2 && (ctInv[1] = data.SMARTPI_CHANGECURRENTDIRECTION_2);
          data.SMARTPI_CHANGECURRENTDIRECTION_3 && (ctInv[2] = data.SMARTPI_CHANGECURRENTDIRECTION_3);
          setCtInvert(ctInv);
          setOriginalCtInvert(ctInv);

        }
      });
      return () => unsubscribe();
    }

  }, []);

  useEffect(() => {
    console.log(ctType, originalCtType);
  }, [ctType, originalCtType]);

  return (
    <>
      <Alert variant="info">Nachfolgende Einstellungen beziehen sich auf die integrierte Messung des Smart Charge Controllers.</Alert>
      <Row>
        <Col>
          <FormGroup>
            <Form.Label>Messwandlertyp</Form.Label>
            <Form.Select value={ctType} onChange={(e) => setCtType(e.target.value)}>
              <option value="YHDC_SCT013">100A/50mA (Standardmesswandler)</option>
              <option value="X/1A">X/1A</option>
            </Form.Select>
          </FormGroup>
        </Col>
        <Col>
          {ctType === 'X/1A' && (        
            <FormGroup>
              <Form.Label>Primärstrom</Form.Label>
              <Form.Control value={ctPrimaryCurrent} onChange={(e) => setCtPrimaryCurrent(e.target.value)} />
            </FormGroup>        
          )}
        </Col>
        <Col>
          <FormGroup>
            <Form.Label>Stromrichtung ändern</Form.Label>
            <Form.Check type="switch" id="0" label="Phase 1" checked={ctInvert[0] !== "0"} onChange={handleSwitchChange} />
            <Form.Check type="switch" id="1" label="Phase 2" checked={ctInvert[1] !== "0"} onChange={handleSwitchChange} />
            <Form.Check type="switch" id="2" label="Phase 3" checked={ctInvert[2] !== "0"} onChange={handleSwitchChange} />
          </FormGroup>
        </Col>
      </Row>
      <br/>
      {ctType !== originalCtType && <Alert variant="warning">Der Messwandlertyp muss mit Jumper auf dem Smart Charge Controller angepasst werden!</Alert>}
      <Row>
        <Col className="d-flex justify-content-end">
          {loading ? <Spinner animation="border" variant="primary" /> :
            <Button onClick={saveCurrentTransformer} disabled={ctType === originalCtType && ctPrimaryCurrent === originalCtPrimaryCurrent && compareArrays(ctInvert, originalCtInvert)}>Speichern</Button>
          }
        </Col>        
      </Row>
    
    </>
  )
}

export default DeviceSettings;