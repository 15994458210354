import React from 'react';

import './App.css';

import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch
} from 'react-router-dom';

import {Container} from 'react-bootstrap';

import Navigation from './_components/Navigation';
import Signup from './_components/_auth/signup';
import EdgesOverview from './_components/EdgesOverview';
import {AuthContextProvider, useAuthState} from './_components/Firebase';
import Statistics from './_components/Statistics';
import Configuration from './_components/Configuration';
import Test from './_components/Test';
import MeterReport from './_components/MeterReport';

import {AuthenticatedRoute} from './_components/_auth';

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <Router>
          <Navigation/>
          <Container className="mt-3 w-100">
            <Switch>
              <AuthenticatedRoute exact path="/" component={EdgesOverview}/>
              <Route exact path="/signup" component={Signup}/>
              <AuthenticatedRoute exact path="/edges/:eid/statistics" component={Statistics}/>
              <AuthenticatedRoute exact path="/edges/:eid/configuration" component={Configuration}/>
              <AuthenticatedRoute exact path="/edges/:eid/meterreport" component={MeterReport}/>
              <Route><Test/></Route>
            </Switch>
          </Container>
        </Router>
      </AuthContextProvider>
    </div>
  );
}

export default App;
