import React, { useState, useEffect } from 'react';

import { Spinner, Card, Form, Table } from 'react-bootstrap';

import { doc, onSnapshot, setDoc, deleteDoc } from 'firebase/firestore';

import { getAuth } from "firebase/auth";

import {db} from '../Firebase';

const NodeRedAdmin = (props) => {
  const [ deviceURLLoading, setDeviceURLLoading ] = useState(true);
  const [ deviceURL, setDeviceURL ] = useState(false);

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "balenaDeviceURLs", props.eid), (doc) => {
      if(doc.exists()) {
        const data = doc.data();
        if(data.deviceURL) {
          setDeviceURL(data.deviceURL);
          setDeviceURLLoading(false);
        } else {
          setDeviceURL("");
          setDeviceURLLoading(true);
        }
        
      } else {
        setDeviceURL(false);
        setDeviceURLLoading(false);
      }
    });
    return () => unsubscribe();
  }, [])

  const onSwitchClick = async() => {
    if(deviceURL) {
      await deleteDoc(doc(db, "balenaDeviceURLs", props.eid));
    } else {
      const uid = getAuth().currentUser.uid;
      await setDoc(doc(db, "balenaDeviceURLs", props.eid), {createdAt: new Date(), createdBy: uid});
    }
  }

  return (
    <>
      <Table>
        <tbody>
          <tr>
            <td>
              URL 
              {deviceURLLoading ? <Spinner animation="border" size="sm"/> : 
                <Form className="d-inline">
                  <Form.Check className="d-inline" type="switch" id="enable-device-url-switch" checked={deviceURL} onChange={onSwitchClick}></Form.Check>
                </Form>
              }
              <br/><small>Der SCC muss online sein</small>
            </td>
            <td>{deviceURL && <a href={deviceURL + "/admin"} target="_blank" rel="noopener noreferrer">{deviceURL + "/admin"}</a>}</td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}

export default NodeRedAdmin;