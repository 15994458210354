import { useCallback, useState } from "react";

export const useCenteredTree = () => {
    const [translate, setTranslate] = useState({ x: 0, y: 0 });
    const containerRef = useCallback((containerElem) => {
        if (containerElem !== null) {
            let { width, height } = containerElem.getBoundingClientRect();
            if(height < 100) {
                height = 100;
            }
            setTranslate({ x: 20, y: height / 2 });
        }
    }, []);
    return [translate, containerRef];
};
