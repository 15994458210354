import React from "react";

import {Table, Form} from 'react-bootstrap';

const ModbusRTUMeterTable = (props) => {

  return (
    <Table>
      <tbody>
      <tr>
        <th className="text-start">Typ</th>
        <td className="text-end">{props.device.deviceType}</td>
      </tr>
      <tr>
        <th className="text-start">Name</th>
        <td className="text-end">{props.device.name}</td>
      </tr>
      <tr>
        <th className="text-start">Modbus ID</th>
        <td className="text-end">{props.device.modbusId}</td>
      </tr>
      <tr>
        <th className="text-start">USB Device</th>
        <td className="text-end">{props.device.usbDevice}</td>
      </tr>
      </tbody>
    </Table>
  )
}

const ModbusRTUMeterForm = (props) => {
  return (
    <Form>
      <Form.Group>
        <Form.Label>Typ</Form.Label>
        <Form.Select
          name="deviceType"
          value={props.device.deviceType}
          onChange={props.handleChange}
        >
          <option value="PRO380-Mod">Pro380 Mod</option>
          <option value="PRO380-Mod-ZEV">Pro380 Mod ZEV</option>
        </Form.Select>
      </Form.Group>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={props.device.name}
          onChange={props.handleChange}/>
      </Form.Group>
      <Form.Group>
        <Form.Label>Modbus ID</Form.Label>
        <Form.Control
          type="integer"
          name="modbusId"
          value={parseInt(props.device.modbusId)}
          onChange={props.handleChange}/>
      </Form.Group>
      <Form.Group>
        <Form.Label>USB Device</Form.Label>
        <Form.Control
          type="text"
          name="usbDevice"
          value={props.device.usbDevice}
          onChange={props.handleChange}/>
      </Form.Group>
    </Form>
  )
}

export {ModbusRTUMeterTable, ModbusRTUMeterForm};